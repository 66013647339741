import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { PathConstants } from '@/pathConstant'
import { MobileMenu } from '@/layout/MobileMenu'
import { DesktopMenu } from '@/layout/DesktopMenu'
import { useMediaQuery } from 'react-responsive'
import { ResponsiveLayout } from '@/assets/style/mixins'
import LeftArrow from '@/assets/icons/chevron-left.svg?react'

const HeaderEl = styled.header`
  position: fixed;
  z-index: 2;
  top: 0;
  display: flex;
  width: 100%;
  max-width: 1440px;
  height: var(--header-height);
  background-color: var(--primary-background-color);
  font-weight: var(--font-weight-semi-bold);
  white-space: nowrap;
  ${ResponsiveLayout.queries};
`

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  gap: 40px;
`

const HomeLink = styled(Link)`
  display: flex;
  width: 100%;
  align-items: center;
  gap: 8px;
`
const LinkText = styled.span`
  font-size: var(--font-text-md);
`

export function Header() {
  const isMobileWidth = useMediaQuery({ query: '(max-width: 743px)' })

  return (
    <HeaderEl>
      <Wrapper>
        <HomeLink to={PathConstants.AVA} aria-label="To Ava Website" title="To Ava Website">
          <LeftArrow />
          <LinkText>Ava Energy website</LinkText>
        </HomeLink>
        {isMobileWidth ? <MobileMenu /> : <DesktopMenu />}
      </Wrapper>
    </HeaderEl>
  )
}
