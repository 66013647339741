export const PathConstants = {
  HOME: '/',
  BUSINESS_COLLECT: 'business-collect',
  RESIDENTIAL: 'residential',
  CONTACT: import.meta.env.VITE_APP_AVA_CONTACT,
  AVA: import.meta.env.VITE_APP_AVA,
  CALCULATOR: 'calculator',
  FORECAST: 'forecast',
  POST_LOGIN: 'post-login',
  POST_LOGOUT: 'post-logout',
}
