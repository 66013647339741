import { Autocomplete } from '@react-google-maps/api'
import styled from 'styled-components'
import { ChangeEvent, useCallback, useState } from 'react'
import '@/assets/style/autocomplete.css'
import { useProfile } from '@/profile/useProfle'
import { debounce } from 'lodash'
import { StyledInput, StyledSubtitle } from './CommonComponents'
import { CalculatorInput } from '@/computeCalculator/CommonCalculator'

type AddressInputProps = {
  onSelectPlace?: (value: string) => void
  isCalculator?: boolean
}

const HomeAddressContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`

export function AddresInput(props: Readonly<AddressInputProps>) {
  const [autocomplete, setAutocomplete] = useState<null | google.maps.places.Autocomplete>(null)
  const { onSelectPlace } = props
  const { profile } = useProfile()

  const onPlaceChanged = useCallback(() => {
    if (!autocomplete) return
    const formattedAddress = autocomplete.getPlace().formatted_address
    if (!formattedAddress || !onSelectPlace) return
    onSelectPlace(formattedAddress)
  }, [autocomplete, onSelectPlace])

  const onLoad = useCallback((autocomplete: google.maps.places.Autocomplete) => {
    setAutocomplete(autocomplete)
  }, [])

  const debouncedOnSelectPlace = debounce(({ target }: ChangeEvent<HTMLInputElement>) => {
    onSelectPlace && !target.value && onSelectPlace(target.value)
  }, 300)

  const handleInputChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      debouncedOnSelectPlace(event)
    },
    [debouncedOnSelectPlace]
  )

  return (
    <HomeAddressContainer>
      <StyledSubtitle>Home Address</StyledSubtitle>
      <Autocomplete
        onLoad={onLoad}
        onPlaceChanged={onPlaceChanged}
        options={{ fields: ['formatted_address', 'address_components'] }}
        types={['address']}
        restrictions={{ country: 'us' }}
      >
        {props.isCalculator ? (
          <CalculatorInput type="text" placeholder="" defaultValue={profile?.address} onChange={handleInputChange} />
        ) : (
          <StyledInput type="text" placeholder="" defaultValue={profile?.address} onChange={handleInputChange} />
        )}
      </Autocomplete>
    </HomeAddressContainer>
  )
}
