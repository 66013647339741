import React from 'react'
import ReactDOM from 'react-dom/client'
import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import { routes } from './routes.tsx'
import '@/assets/style/global.css'
import { initFirebaseAnalytics } from './analytics.ts'
import AuthProvider from './auth/AuthProvider.tsx'
import * as Sentry from '@sentry/react'
import { StyleSheetManager } from 'styled-components'
import { Helmet } from 'react-helmet'
import { faqData, productData } from './markup.ts'
import 'react-toastify/dist/ReactToastify.css'
import { Slide, ToastContainer } from 'react-toastify'

const sentryDSN = import.meta.env.VITE_SENTRY_DSN
const appEnv = import.meta.env.VITE_APP_ENV ?? 'unknown'

if (sentryDSN) {
  Sentry.init({
    dsn: import.meta.env.VITE_SENTRY_DSN as string,
    integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    environment: appEnv,
  })
}

if (import.meta.env.VITE_ENABLE_ANALYTICS === 'true') {
  initFirebaseAnalytics()
}

const rootElement = document.getElementById('root')

if (rootElement) {
  const router = createBrowserRouter(routes(), {
    future: {
      v7_relativeSplatPath: true,
      v7_fetcherPersist: true,
      v7_normalizeFormMethod: true,
      v7_partialHydration: true,
      v7_skipActionErrorRevalidation: true,
    },
  })

  ReactDOM.createRoot(rootElement).render(
    <React.StrictMode>
      <StyleSheetManager enableVendorPrefixes={true}>
        <Helmet>
          <script type="application/ld+json">{JSON.stringify(faqData)}</script>
          <script type="application/ld+json">{JSON.stringify(productData)}</script>
        </Helmet>
        <AuthProvider>
          <ToastContainer
            position="bottom-center"
            autoClose={4000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick={true}
            rtl={false}
            theme="dark"
            transition={Slide}
          />
          <RouterProvider
            router={router}
            future={{
              v7_startTransition: true,
            }}
          />
        </AuthProvider>
      </StyleSheetManager>
    </React.StrictMode>
  )
} else {
  console.error("Element with id 'root' not found.")
}
