import { useAuth0 } from '@auth0/auth0-react'
import { useEffect, useState } from 'react'
import { internalApi } from '@/api/axios'
import { Profile } from '@/profile/profile'
import { useProfile } from '@/profile/useProfle'

type RegisterSessionRootProps = {
  children: React.ReactNode
}

type User = {
  profile: Profile
}

export type GetToken = () => Promise<string>

async function registerSession(getToken: GetToken) {
  const token = await getToken()
  const payload: { threadId?: string } = {}
  const threadId = localStorage.getItem('thread_id')
  if (threadId) {
    payload['threadId'] = threadId
  }
  const { data: user } = await internalApi.post<User>('/session', payload, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
  if (user.profile.thread_id && !threadId) {
    localStorage.setItem('thread_id', user.profile.thread_id)
  }
  return token
}

async function registerSessionWithRetries(getToken: GetToken) {
  let retryCount = 0
  const maxRetries = 3

  while (retryCount < maxRetries) {
    try {
      return await registerSession(getToken)
    } catch (error) {
      console.error('Session registration failed', error)
      retryCount += 1
      await new Promise((resolve) => setTimeout(resolve, 1000 * retryCount))
    }
  }
}

export const RegisterSessionRoot = ({ children }: RegisterSessionRootProps) => {
  const { user, getAccessTokenSilently, isLoading: auth0IsLoading } = useAuth0()
  const { setToken } = useProfile()
  const [sessionLoaded, setSessionLoaded] = useState(false)

  const userId = user?.sub || null

  useEffect(() => {
    if (!userId) {
      return
    }
    registerSessionWithRetries(getAccessTokenSilently)
      .then((res) => {
        if (res) setToken(res)
      })
      .catch(() => {})
      .finally(() => {
        setSessionLoaded(true)
      })
  }, [userId, getAccessTokenSilently, setToken, sessionLoaded])

  if (auth0IsLoading || (userId && !sessionLoaded)) {
    return null
  }

  return <>{children}</>
}
