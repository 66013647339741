import { useState } from 'react'
import styled from 'styled-components'
import HamburgerIcon from '@/assets/icons/hamburger.svg?react'
import CloseIcon from '@/assets/icons/x-close.svg?react'
import { LoginControl } from '@/auth/LoginControl'
import UserIcon from '@/assets/icons/user.svg?react'
import { useAuth0 } from '@auth0/auth0-react'

const MobileMenuEl = styled.button`
  margin-left: auto;
`

const LinkWrapperEl = styled.nav`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: var(--header-height);
  background-color: var(--base-white);
  box-shadow:
    0 4px 6px -2px #10182808,
    0 12px 16px -4px #10182814;
`

const LoginLinkWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  padding: 24px 16px;
  gap: 32px;
`

const DropDownUserContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 16px;
  border-bottom: 1px solid var(--gray-light-mode-200);
  gap: 12px;
`

const DropDownIconContainer = styled.div`
  display: flex;
  width: 56px;
  height: 56px;
  align-items: center;
  justify-content: center;
  padding: 8px;
  border: solid 1px rgb(0 0 0 / 8%);
  border-radius: 9999px;
  background-color: var(--gray-light-mode-100);
`

const DropDownUserName = styled.span`
  color: #344054;
  font-size: var(--font-text-lg);
  font-weight: var(--font-weight-semi-bold);
`

const DropDownUserEmail = styled.span`
  color: #475467;
  font-weight: var(--font-weight-normal);
`

const DropDownUserData = styled.div`
  display: grid;
`

export function MobileMenu() {
  const [isOpen, setIsOpen] = useState(false)
  const { user, isLoading, isAuthenticated } = useAuth0()
  if (isLoading) return <></>
  const username = user?.preferred_username ?? user?.nickname ?? 'no username'

  return (
    <>
      <MobileMenuEl
        type="button"
        onClick={() => {
          setIsOpen(!isOpen)
        }}
      >
        {isOpen ? <CloseIcon /> : <HamburgerIcon />}
      </MobileMenuEl>
      {isOpen && (
        <LinkWrapperEl>
          {isAuthenticated && (
            <DropDownUserContainer>
              <DropDownIconContainer>
                <UserIcon width={28} height={28} stroke="var(--gray-light-mode-500)" />
              </DropDownIconContainer>
              <DropDownUserData>
                <DropDownUserName>{username}</DropDownUserName>
                <DropDownUserEmail>{user?.email && user.email}</DropDownUserEmail>
              </DropDownUserData>
            </DropDownUserContainer>
          )}
          <LoginLinkWrapper>
            <LoginControl />
          </LoginLinkWrapper>
        </LinkWrapperEl>
      )}
    </>
  )
}
