import { Title, Option, IncentivesPrice } from '@/uiComponents/CommonComponents'
import { Container } from './CommonCalculator'
import Select from './Select'
import styled from 'styled-components'
import { usdFormatter } from '@/salesAssistant/packagePreview/usdFormatter'
import { CalculatorResponse } from './computeCalculatorApi'

const EstimatedSystemTitle = styled(Title)`
  margin-bottom: 40px;
`

const SystemOptionContainer = styled.div`
  display: flex;
  width: 100%;
  max-width: 300px;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 14px;
  border-radius: var(--radius-2xl);
  background-color: var(--gray-modern-50);
  cursor: default;
  gap: 8px;
`

const SystemOptionsWrapper = styled.div`
  display: grid;
  margin: 24px 0;
  gap: 24px;
  grid-template-columns: 1fr;

  @media (min-width: 620px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (min-width: 1248px) {
    display: flex;
    flex-direction: row;
  }
`

const DataPointTitle = styled.span`
  font-size: var(--font-text-lg);
  font-weight: var(--font-weight-bold);
`

const DataPoint = styled.li`
  font-size: var(--font-text-md);
  font-weight: var(--font-weight-normal);
`

const DataPointLabel = styled.span`
  font-weight: var(--font-weight-semi-bold);
`

type EstimatedSystemProps = {
  calculatorProfits: CalculatorResponse
  period: 'monthly' | 'yearly'
  setPeriod: React.Dispatch<React.SetStateAction<'monthly' | 'yearly'>>
}

const EstimatedSystem = ({ calculatorProfits, period, setPeriod }: EstimatedSystemProps) => {
  const { yearly } = calculatorProfits
  const isMonthly = period === 'monthly'
  const grid = yearly.energySources.grid
  const solar = yearly.energySources.solar
  const battery = yearly.energySources.battery
  const billsavings = yearly.profits.billSavings
  const sellingEnergy = yearly.profits.sellingEnergy
  const gpyRent = yearly.profits.gpuRent
  const storageRent = yearly.profits.storageRent

  const formatProfitValue = (value: number) => usdFormatter.format(isMonthly ? value / 12 : value)
  const periodValue = (value: number) => (isMonthly ? (value / 12).toFixed(2) : value)

  return (
    <Container>
      <EstimatedSystemTitle>Estimated system cost & profits</EstimatedSystemTitle>
      <Select
        width="264"
        onChange={(value) => {
          setPeriod(value as 'monthly' | 'yearly')
        }}
        value={period}
      >
        <Option value="monthly">Monthly</Option>
        <Option value="yearly">Yearly</Option>
      </Select>
      <SystemOptionsWrapper>
        <SystemOptionContainer>
          <DataPointTitle>Grid</DataPointTitle>
          <DataPoint>
            <DataPointLabel>Total used:</DataPointLabel> {periodValue(grid.totalUsed)} kWh
          </DataPoint>
          <DataPoint>
            <DataPointLabel>Rate:</DataPointLabel> {grid.rate} $/kWh
          </DataPoint>
          <DataPoint>
            <DataPointLabel>Total cost:</DataPointLabel> {formatProfitValue(grid.totalCost)}
          </DataPoint>
          <DataPointLabel>Usage:</DataPointLabel>
          <DataPoint>
            <DataPointLabel>Consumption:</DataPointLabel> {periodValue(grid.usage.toConsumption)} kWh
          </DataPoint>
          <DataPoint>
            <DataPointLabel>
              <DataPointLabel>Battery:</DataPointLabel>
            </DataPointLabel>
            {periodValue(grid.usage.toBattery)} kWh
          </DataPoint>
          <DataPoint>
            <DataPointLabel>GPU Network:</DataPointLabel> {periodValue(grid.usage.toGPUNetwork)} kWh
          </DataPoint>
        </SystemOptionContainer>
        <SystemOptionContainer>
          <DataPointTitle>Solar</DataPointTitle>
          <DataPoint>
            <DataPointLabel>Total production: </DataPointLabel> {periodValue(solar.totalProduction)} kWh
          </DataPoint>
          <DataPoint>
            <DataPointLabel>Total used: </DataPointLabel> {periodValue(solar.totalUsed)} kWh
          </DataPoint>
          <DataPointLabel>Usage: </DataPointLabel>
          <DataPoint>
            <DataPointLabel>Consumption: </DataPointLabel> {periodValue(solar.usage.toConsumption)} kWh
          </DataPoint>
          <DataPoint>
            <DataPointLabel>Battery: </DataPointLabel> {periodValue(solar.usage.toBattery)} kWh
          </DataPoint>
          <DataPoint>
            <DataPointLabel>GPU Network: </DataPointLabel> {periodValue(solar.usage.toGPUNetwork)} kWh
          </DataPoint>
          <DataPoint>
            <DataPointLabel>
              <DataPointLabel>Grid: </DataPointLabel>
            </DataPointLabel>
            {periodValue(solar.usage.toGrid)} kWh
          </DataPoint>
        </SystemOptionContainer>
        <SystemOptionContainer>
          <DataPointTitle>Battery</DataPointTitle>
          <DataPoint>
            <DataPointLabel>Capacity: </DataPointLabel> {battery.capacity} kWh
          </DataPoint>
          <DataPoint>
            <DataPointLabel>Throughput: </DataPointLabel> {periodValue(battery.throughput)} kWh
          </DataPoint>
          <DataPointLabel>Usage: </DataPointLabel>
          <DataPoint>
            <DataPointLabel> Consumption: </DataPointLabel> {periodValue(battery.usage.toConsumption)} kWh
          </DataPoint>
          <DataPoint>
            <DataPointLabel>GPU Network: </DataPointLabel> {periodValue(battery.usage.toGPUNetwork)} kWh
          </DataPoint>
          <DataPoint>
            <DataPointLabel>Grid: </DataPointLabel> {periodValue(battery.usage.toGrid)} kWh
          </DataPoint>
        </SystemOptionContainer>
      </SystemOptionsWrapper>
      <SystemOptionsWrapper>
        <SystemOptionContainer>
          <DataPointTitle>Bill Savings</DataPointTitle>
          <DataPoint>
            <DataPointLabel>Total consumption: </DataPointLabel> {periodValue(billsavings.totalConsumption)} kWh
          </DataPoint>
          <DataPoint>
            <DataPointLabel>Total cost: </DataPointLabel>
            {formatProfitValue(billsavings.totalCost)}
          </DataPoint>
          <DataPoint>
            <DataPointLabel>Previous cost: </DataPointLabel>
            {formatProfitValue(billsavings.previousCost)}
          </DataPoint>
          <DataPoint>
            <DataPointLabel>Profit: </DataPointLabel> {formatProfitValue(billsavings.profit)}
          </DataPoint>
        </SystemOptionContainer>
        <SystemOptionContainer>
          <DataPointTitle>Selling Energy (Grid)</DataPointTitle>
          <DataPoint>
            <DataPointLabel>Total energy sold: </DataPointLabel> {periodValue(sellingEnergy.totalEnergySold)} kWh
          </DataPoint>
          <DataPoint>
            <DataPointLabel>
              <DataPointLabel>Rate: </DataPointLabel>
            </DataPointLabel>
            {sellingEnergy.rate} $/kWh
          </DataPoint>
          <DataPoint>
            <DataPointLabel>Profit: </DataPointLabel> {formatProfitValue(sellingEnergy.profit)}
          </DataPoint>
        </SystemOptionContainer>
        <SystemOptionContainer>
          <DataPointTitle>GPU Rent</DataPointTitle>
          <DataPoint>
            <DataPointLabel>Total consumption: </DataPointLabel> {periodValue(gpyRent.totalConsumption)} kWh
          </DataPoint>
          <DataPoint>
            <DataPointLabel>Total grid cost: </DataPointLabel> {formatProfitValue(gpyRent.totalGridCost)}
          </DataPoint>
          <DataPoint>
            <DataPointLabel>Active hours: </DataPointLabel> {periodValue(gpyRent.activeHours)} $/h
          </DataPoint>
          <DataPoint>
            <DataPointLabel>Rate: </DataPointLabel> {gpyRent.rate} $/kWh
          </DataPoint>
          <DataPoint>
            <DataPointLabel>Profit: </DataPointLabel>
            {formatProfitValue(gpyRent.profit)}
          </DataPoint>
        </SystemOptionContainer>
        <SystemOptionContainer>
          <DataPointTitle>Storage Rent</DataPointTitle>
          <DataPoint>
            <DataPointLabel>Allocated space: </DataPointLabel>: {periodValue(storageRent.allocatedSpace)} GB
          </DataPoint>
          <DataPoint>
            <DataPointLabel>Rate: </DataPointLabel> {storageRent.rate.value} {storageRent.rate.unit}
          </DataPoint>
          <DataPoint>
            <DataPointLabel>Profit: </DataPointLabel> {formatProfitValue(storageRent.profit)}
          </DataPoint>
        </SystemOptionContainer>
      </SystemOptionsWrapper>
      <SystemOptionContainer style={{ maxWidth: 204 }}>
        <DataPointTitle>Total Profits</DataPointTitle>
        <IncentivesPrice>{formatProfitValue(yearly.profits.totalProfit)}</IncentivesPrice>
      </SystemOptionContainer>
    </Container>
  )
}

export default EstimatedSystem
