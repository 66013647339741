import styled from 'styled-components'
import { LoginControl } from '@/auth/LoginControl'

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  gap: 40px;
`

const Actions = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;
  gap: 12px;
`

export function DesktopMenu() {
  return (
    <Wrapper>
      <Actions>
        <LoginControl />
      </Actions>
    </Wrapper>
  )
}
